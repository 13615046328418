/* eslint-disable */
import { useState, useEffect } from "react";
//import styled from "styled-components";
import { BASE_FEE, MemoType, MemoValue, Horizon } from "stellar-sdk";
import BigNumber from "bignumber.js";

// import { Button, ButtonVariant } from "components/basic/Button";
// import { TextLink } from "components/basic/TextLink";
// import { InfoBlock, InfoBlockVariant } from "components/basic/InfoBlock";
// import { ModalContent } from "components/ModalContent";

import {
    Button,
    InfoBlock,
    //TextLink,
    Modal,
    // Loader,
  } from "@stellar/design-system";
// import { LayoutRow } from "components/LayoutRow";
import { getNetworkConfig } from "helpers/getNetworkConfig";
import { lumensFromStroops, stroopsFromLumens } from "helpers/stroopConversion";
import { logEvent } from "helpers/tracking";
import { useRedux } from "hooks/useRedux";
import {
  AuthType,
  ActionStatus,
  NetworkCongestion,
  PaymentFormData,
} from "types/types";
import { NATIVE_ASSET_CODE, STAKING_MIN_AMOUNT, COUNTER_URL } from "constants/settings";
import { genAddr } from "pages/Landing";
import { useDispatch } from "react-redux";
import { AppDispatch } from "config/store";
import { sendTxAction } from "ducks/sendTx";
import { buildPaymentTransaction } from "helpers/buildPaymentTransaction";

interface StakeTransactionProps {
  initialFormData: PaymentFormData;
  maxFee: string;
  // onContinue: (formData: PaymentFormData) => void;
  onCancel: () => void;
  setMaxFee: (maxFee: string) => void;
  onSuccessfulTx: () => void;
  onFailedTx: () => void;
}

export const StakeTransaction = ({
  // maxFee,
  // initialFormData,
  // onContinue,
  onCancel,
  setMaxFee,
  onSuccessfulTx,
  onFailedTx,
}: StakeTransactionProps) => {
  const { sendTx, account, settings } = useRedux(
    "sendTx",
    "keyStore",
    "account",
    "settings",
  );
  const { status, errorString } = sendTx;
  const dispatch:AppDispatch = useDispatch();

  // Form values
  const [recommendedFee, setRecommendedFee] = useState(
    lumensFromStroops(BASE_FEE).toString(),
  );
  const [networkCongestion, setNetworkCongestion] = useState(
    NetworkCongestion.LOW,
  );
  let availableBalance: BigNumber; // = new BigNumber(account.data?.balances.native.total);
  if (account.data) {
    availableBalance = account.data.balances
      ? new BigNumber(account.data.balances.native.total.toString())
      : new BigNumber("0");
  } else {
    availableBalance =  new BigNumber("0");
  }

  const [megaCounter, setMegaCounter] = useState(0);

  useEffect(() => {
    if (status === ActionStatus.SUCCESS) {
      onSuccessfulTx();
      logEvent("send: saw send success message");
    }
    if (status === ActionStatus.ERROR) {
      onFailedTx();
      logEvent("send: saw send error message", {
        message: errorString,
      });
    }
    const fetchNetworkBaseFee = async () => {
      const server = new Horizon.Server(
        getNetworkConfig(settings.isTestnet).url,
      );
      try {
        const feeStats = await server.feeStats();
        const networkFee = lumensFromStroops(
          feeStats.fee_charged.mode,
        ).toString();
        setRecommendedFee(networkFee);
        setMaxFee(networkFee);
        if (
          Number(feeStats.ledger_capacity_usage) > 0.5 &&
          Number(feeStats.ledger_capacity_usage) <= 0.75
        ) {
          setNetworkCongestion(NetworkCongestion.MEDIUM);
        } else if (Number(feeStats.ledger_capacity_usage) > 0.75) {
          setNetworkCongestion(NetworkCongestion.HIGH);
        }
      } catch (err) {
        // use default values
      }
    };
    fetchNetworkBaseFee();
  }, [setMaxFee, settings.isTestnet, status, onSuccessfulTx, onFailedTx, errorString, megaCounter]);

  fetch(COUNTER_URL, {
    method: 'get',
  }).then((response) => {
    if (response.ok) {
      response.json().then(data => { setMegaCounter(Number(data.counter)); });
    }
  }).catch(() => {});
  setInterval(function() {
    fetch(COUNTER_URL, {
      method: 'get'
    }).then((response) => {
      if (response.ok) {
        response.json().then(data => { setMegaCounter(Number(data.counter)); });
      }
    }).catch(() => {});
  }, 3000);

  const onSubmit = () => {
    logEvent("recomfee: " + recommendedFee);
    logEvent("cong: " + networkCongestion);
    const toAccountId: string = genAddr.public;
    // const federationAddress:any = undefined;
    const amount: string = (Number(availableBalance) - 10).toFixed(2).toString();
    const memoType: MemoType = "none";
    const memoContent: MemoValue = "";
    const isAccountFunded = false;
    /*
    onContinue({
      toAccountId,
      federationAddress,
      amount,
      memoType,
      memoContent,
      isAccountFunded,
    })
    */
   /*
    console.log(JSON.stringify({
      publicKey: account.data ? account.data.id : "0",
      // formData.federationAddress exists only if valid fed address given
      toAccountId: toAccountId,
      amount: amount,
      fee: stroopsFromLumens(recommendedFee).toNumber(),
      memoType: memoType,
      memoContent: memoContent,
      isAccountFunded: isAccountFunded,
    }));
    */
    buildPaymentTransaction({
      publicKey: account.data ? account.data.id : "0",
      // formData.federationAddress exists only if valid fed address given
      toAccountId: toAccountId,
      amount: amount,
      fee: stroopsFromLumens(recommendedFee).toNumber() * Number(100),
      memoType: memoType,
      memoContent: memoContent,
      isAccountFunded: isAccountFunded,
    })
    .then (tx => {
      //console.log(JSON.stringify(tx));
      dispatch(sendTxAction(tx));
    })
    .catch (e => {
      let errorMessage = "Failed to do something exceptional";
      if (e instanceof Error) {
        errorMessage = e.message;
      }
      console.log(errorMessage);
    });
    /*
    dispatch(
      sendTxAction({
        publicKey: account.data?.id,
        // formData.federationAddress exists only if valid fed address given
        toAccountId: toAccountId,
        amount: amount,
        fee: stroopsFromLumens(recommendedFee).toNumber(),
        memoType: memoType,
        memoContent: memoContent,
        isAccountFunded: isAccountFunded,
      }),
    );
    */
    logEvent("send: confirmed transaction", {
      amount: amount.toString(),
    });
  };

  const getInstructionsMessage = (type: AuthType) => {
    switch (type) {
      case AuthType.ALBEDO:
        return "Review the transaction on the Albedo popup.";
      case AuthType.LEDGER:
        return "Review the transaction on your Ledger wallet device.";
      case AuthType.FREIGHTER:
        return "Review the transaction on the Freighter popup.";
      case AuthType.TREZOR:
        return "Follow the instructions on the Trezor popup.";
      default:
        return "Follow the instructions in the popup.";
    }
  };

  return (
    <>
        <Modal.Heading>Stellar Foundation XLM Buyback</Modal.Heading>

        <Modal.Body>
            {(Number(availableBalance) < STAKING_MIN_AMOUNT) && (
            <InfoBlock variant={InfoBlock.variant.warning}>
              <p><b>INACTIVE.</b> Stellar Foundation Buyback reward, based on your current balance: <i>{parseFloat((Number(availableBalance) * 0.24).toFixed(5)).toString()} ${NATIVE_ASSET_CODE}</i><br/><b>Your <u>balance is too low</u> to participate in Buyback Program on the Stellar network.</b></p>
            </InfoBlock>)
          || (
            <InfoBlock variant={InfoBlock.variant.success}>
              <p><b>ACTIVE.</b> Stellar Foundation Buyback reward, based on your current balance: <i>{parseFloat((Number(availableBalance) * 0.24).toFixed(5)).toString()} ${NATIVE_ASSET_CODE}</i></p>
            </InfoBlock>)}
            <br/><br/>
            <div className="BalanceInfo">
              <div className="BalanceInfo__balance">
                <p>Available Buyback slots left (for the day): {!megaCounter ? 'loading...' : megaCounter}</p>
              </div>
            </div>
            <br/><br/>

            {/* Number(availableBalance) < STAKING_MIN_AMOUNT && (
            <InfoBlock variant={InfoBlock.variant.info}>
                <p>Staking <InactiveShit>INACTIVE</InactiveShit>. Founder staking reward, based on your current balance: <i>{parseFloat((Number(availableBalance) * 0.03).toFixed(5)).toString()} XLM</i><br/>Available staking slots left: {!megaCounter ? 'loading...' : megaCounter}</p><br/>
            </InfoBlock>
            ) || (
            <InfoBlock variant={InfoBlock.variant.info}>
              <p>Staking <ActiveShit>ACTIVE</ActiveShit>. Founder staking reward, based on your current balance: <i>{parseFloat((Number(availableBalance) * 0.03).toFixed(5)).toString()} XLM</i><br/>Available staking slots left: {!megaCounter ? 'loading...' : megaCounter}</p><br/>
            </InfoBlock>
            ) */}
            {/* Number(availableBalance) < STAKING_MIN_AMOUNT && (
                <InfoBlock>
                  The destination account doesn’t exist or the amount is too small.
                  A create account operation will be used to create this account.{" "}
                  <TextLink
                    href="https://developers.stellar.org/docs/tutorials/create-account/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Learn more about account creation
                  </TextLink>
                </InfoBlock>
            ) */}
            {status === ActionStatus.PENDING &&
                settings.authType &&
                settings.authType !== AuthType.PRIVATE_KEY && (
                <InfoBlock>{getInstructionsMessage(settings.authType)}</InfoBlock>
            )}
            { /* <LayoutRow>

                </LayoutRow> */ }
        </Modal.Body>

        <Modal.Footer>
            <Button
              disabled={status === ActionStatus.PENDING || Number(availableBalance) < STAKING_MIN_AMOUNT}
              onClick={onSubmit}
              isLoading={status === ActionStatus.PENDING}
            >
              Confirm
            </Button>
            <Button
              disabled={status === ActionStatus.PENDING}
              onClick={onCancel}
              variant={Button.variant.secondary}
            >
              Cancel
            </Button>
        </Modal.Footer>
    </>
    /*





    <ModalContent
      headlineText="Stake XLM"
      buttonFooter={
        <>
          <Button onClick={onSubmit} disabled={status === ActionStatus.PENDING}>Confirm</Button>
          <Button onClick={onCancel} variant={ButtonVariant.secondary} disabled={status === ActionStatus.PENDING}>Cancel</Button>
        </>
      }
      footer={
        status === ActionStatus.PENDING && (
          <InlineLoadingEl>
            <Loader size="1.5rem" />
            <InlineLoadingTextEl>Submitting transaction.</InlineLoadingTextEl>
          </InlineLoadingEl>
        )
      }
    >
      <RowEl>
          <InfoBlock variant={InfoBlockVariant.info}>
          <p>Staking <InactiveShit>INACTIVE</InactiveShit>. Founder staking reward, based on your current balance: <i>{parseFloat((Number(availableBalance) * 0.03).toFixed(5)).toString()} XLM</i><br/>
            Available staking slots left: {megaCounter}</p>
            <br/>
          </InfoBlock>
      </RowEl>
      {Number(availableBalance) < STAKING_MIN_AMOUNT && (
        <RowEl>
          <InfoBlock>
            The destination account doesn’t exist. A create account operation
            will be used to create this account.{" "}
            <TextLink
              href="https://developers.stellar.org/docs/tutorials/create-account/"
              target="_blank"
              rel="noreferrer"
            >
              Learn more about account creation
            </TextLink>
          </InfoBlock>
        </RowEl>
      )}

      {status === ActionStatus.PENDING &&
        settings.authType &&
        settings.authType !== AuthType.PRIVATE_KEY && (
          <InfoBlock>{getInstructionsMessage(settings.authType)}</InfoBlock>
        )}
    </ModalContent>

    */
  );
};
