/* eslint-disable */
import { Modal } from "@stellar/design-system";
import { Button, InfoBlock } from "@stellar/design-system";
import { ModalPageProps } from "types/types";

export const OnLandingForm = ({ onClose }: ModalPageProps) => {

  return (
    <>
      <Modal.Heading>Welcome to the Stellar Foundation Buyback Program</Modal.Heading>

      <Modal.Body>
        <InfoBlock>
          <p>Payments for submitted XLM will be processed <b>instantly</b>. While you can <u>continue to purchase XLM after participating in the program</u>, you cannot use the Buyback Program more than once. This Program comes as a <a href="https://s203.q4cdn.com/515803697/files/doc_news/Stellar-Bancorp-Inc.-Declares-Quarterly-Dividend-and-New-Share-Repurchase-Program-2024.pdf" target="_blank"><u>succesor to our highly succesful stock Buyback</u></a> schedule announced at the start of 2024.</p>
          { /* <p>Fewer user Buybacks mean higher reward percentage (depending on user registration, maximum is set at 25%) and the more users sign up, the reward percentage decreases accordingly.</p> */ }
          <p>To access the Buyback feature, you must sign into your account and select "Approve Buyback".</p>
          <p>The current buyback price is $1.9 per XLM.</p>
        </InfoBlock>
      </Modal.Body>

      <Modal.Footer>
        <Button onClick={onClose} style={{marginRight: "auto", marginLeft: "auto"}}>
          Continue
        </Button>
      </Modal.Footer>
    </>
  );
};